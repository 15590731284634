'use client';

import React, { FC } from 'react';
import { SiTelegram } from '@icons-pack/react-simple-icons';

import { Button } from '../ui/button';
import { useRouter } from 'next/navigation';
import { generalUrlFromWindow } from 'app/_auth/subdomains';

interface ITelegramLogin {
  botHandle?: string | null;
  url?: string | null;
}

export const TelegramLogin: FC<ITelegramLogin> = ({ botHandle, url }) => {
  const redirectTo = `${generalUrlFromWindow(window)}/tg/login?botHandle=${botHandle}&url=${encodeURI(url || '')}`;
  const router = useRouter();
  return (
    <div>
      <Button
        variant='blue'
        size='medium'
        className='gap-3 bg-[#28A8E8] border-[#28A8E8] hover:bg-[#28A8E8]/80 hover:border-[#28A8E8]/80 w-full'
        onClick={() => {
          void router.push(redirectTo);
        }}
      >
        <SiTelegram />
        Sign in with Telegram
      </Button>
    </div>
  );
};
