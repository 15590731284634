'use client';

import React, { FC, useContext } from 'react';
import NextImage from 'next/image';

import { useMedia } from 'react-use';

import { Button } from 'components/ui/button';
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTrigger
} from 'components/ui/sheet';
import { BurgerIcon, CloseIcon } from 'components/icons/v2';

import { ILandingConfig } from '../../index';
import LandingContext, { ModalTabs } from 'app/_context/Landing';

interface IHeaderProps {
  logo?: string | null;
  communityName: string;
  appConfig: ILandingConfig;
}

const MobileMenu: FC<IHeaderProps> = (props) => {
  const {
    appConfig,
    communityName,
    logo,
  } = props;

  const { setTab } = useContext(LandingContext);

  return (
    <Sheet>
      <SheetTrigger>
        <BurgerIcon />
      </SheetTrigger>
      <SheetContent side="top" className="bg-white p-4">
        <SheetHeader className="flex-row justify-between">
          <div className="flex gap-2 items-center">
            {!!logo && (
              <NextImage src={logo} width={41} height={41} alt="Community-logo" />
            )}
            <p className="font-montserrat text-display-xs font-semibold lg:text-[16px]/[20px]">{communityName}</p>
          </div>
          <Button variant="nakedGrayLink" className="p-0 focus-visible:outline-none" asChild>
            <SheetTrigger>
              <CloseIcon />
            </SheetTrigger>
          </Button>
        </SheetHeader>
        <div className="py-6 flex flex-col">
          <Button variant="nakedGrayLink" size="large" onClick={() => setTab(() => ModalTabs.LOGIN)}>Log in</Button>
          <Button variant={appConfig.buttonVariant} size="large"
                  onClick={() => setTab(() => ModalTabs.APPLY)}>Join</Button>
        </div>
      </SheetContent>
    </Sheet>
  );
}

export const Header: FC<IHeaderProps> = (props) => {
  const {
    communityName,
    logo,
    appConfig
  } = props;

  const isLaptop = useMedia('(min-width: 768px)', true);

  const { setTab } = useContext(LandingContext);

  return (
    <header className="flex px-[115px] py-4 justify-between lg:px-4">
      <div className="flex gap-2 items-center">
        {!!logo && (
          <NextImage src={logo} width={41} height={41} alt="Community-logo" />
        )}
        <p className="font-montserrat text-display-xs font-semibold lg:text-[16px]/[20px]">{communityName}</p>
      </div>
      <div className="flex gap-1">
        {!isLaptop ? (<MobileMenu appConfig={appConfig} communityName={communityName} logo={logo} />) : (
          <>
            <Button variant={appConfig.buttonVariant} size="large"
                    onClick={() => setTab(() => ModalTabs.APPLY)}>Join</Button>
            <Button variant="nakedGrayLink" size="large" onClick={() => setTab(() => ModalTabs.LOGIN)}>Log in</Button>
          </>
        )}
      </div>
    </header>
  );
};
