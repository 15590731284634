export function extractVideoId(url: string) {
  const pattern = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:embed\/|v\/|watch\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

  const match = url.match(pattern);

  if (match) {
    return match[1];
  } else {
    return null;
  }
}
