export const GENERAL_SUBDOMAIN = 'app';


export const communityUrlFromWindow = (window: any, subdomain: string, domain?: string) => {
  /*
   * Builds community url from window object
   * window.location.hostname could be:
   * 1. <subdomain>.gokyg.com
   * 2. <app>.gokyg.com GENERAL_SUBDOMAIN
   * 3. custom-domain.io - custom domain
   *
   * if custom domain is present, we return custom domain
   * else: we replace <app>/<subdomain> with subdomain
   */
  let host;

  if (domain) {
    host = domain;
  } else {
    host = window.location.hostname.replace(GENERAL_SUBDOMAIN, subdomain);
  }

  return `${window.location.protocol}//${host}`;
};
type EnvGeneralSubdomain = {
  [key: string]: string;
};

const generalDomains: EnvGeneralSubdomain = {
  'local': 'app.localhost.io',
  'staging': 'app.staging.gokyg.com',
  'production': 'app.gokyg.com'
};

export const generalUrlFromWindow = (window: any) => {
  let host = window.location.hostname;
  if (!host.includes('.localhost.io') && !host.includes('staging.gokyg.com') && !host.includes('gokyg.com')) {
    // custom domain
    host = generalDomains[process.env.ENV as string];
  } else {
    // subdomain
    const subdomain = host.split('.')[0];
    host = host.replace(subdomain, 'app', 1);
  }
  const port = window.location.port ? `:${window.location.port}` : '';
  return `${window.location.protocol}//${host}${port}`;
};
