'use client';

import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMedia, usePrevious } from 'react-use';

interface SliderConfig {
  pagination?: {
    dotsContainerStyle?: string;
    activeDotStyle?: string;
    inactiveDotStyle?: string;
  };
  slide?: {
    wrapperClassNames?: string;
  };
  slider?: {
    wrapperClassNames?: string;
  };
  loop?: boolean;
}

interface ISliderProps {
  perPage?: number;
  dynamicPerPage?: boolean;
  children: React.ReactNode;
  config?: SliderConfig;
  autoPlay?: boolean;
  autoPlayInterval?: number;
}

const CARD_WIDTH = 280;

export const Slider: FC<ISliderProps> = (props) => {
  const {
    autoPlay,
    autoPlayInterval,
    dynamicPerPage = true,
    children,
    config,
  } = props;

  const isMobile = useMedia('(max-width: 768px)', false);

  const CONTAINER_GAP = useMemo(() => !props.dynamicPerPage && props.perPage === 1 ? 0 : (isMobile ? 16 : 32), [isMobile, props.dynamicPerPage, props.perPage]);
  const CONTAINER_PADDING = useMemo(() => !props.dynamicPerPage && props.perPage === 1 ? 0 : (isMobile ? 16 : 32), [isMobile, props.dynamicPerPage, props.perPage]);

  const sliderContainerRef = useRef<HTMLDivElement>(null);
  const slidesContainerRef = useRef<HTMLDivElement>(null);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [perPage, setPerPage] = useState(props.perPage ?? 4);
  const [sliderOffset, setOffset] = useState<number>(0);
  const [slideWidth, setSlideWidth] = useState<number>(0);

  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [touchEnd, setTouchEnd] = useState<number | null>(null);
  const [mouseDown, setMouseDown] = useState(false);

  const threshold = 50;

  const totalCount = useMemo(() => React.Children.count(children), [children]);
  const totalPaginationDots = useMemo(() => Math.max(0, totalCount - (perPage - 1)), [perPage, totalCount]);
  const isSliderEnabled = useMemo(() => totalCount > perPage, [perPage, totalCount]);

  const isSliderEnabledPrev = usePrevious(isSliderEnabled);

  const handleChangeSlide = useCallback(
    (index: number) => {
      if (index === currentIndex) return;

      const containerWidth = sliderContainerRef.current?.clientWidth ?? 0;
      const slidesTotalWidth = totalCount * (CARD_WIDTH + CONTAINER_GAP) - CONTAINER_GAP;

      if (index === 0) {
        setOffset(() => 0);
      } else if (index >= totalPaginationDots - 1 && !(props.perPage === 1 && !props.dynamicPerPage)) {
        const maxOffset = slidesTotalWidth - containerWidth + CONTAINER_PADDING * 2;
        setOffset(() => Math.max(0, maxOffset));
      } else {
        setOffset(() => index * (slideWidth + CONTAINER_GAP));
      }

      setCurrentIndex(() => (index >= 0 ? index : 0));
    },
    [currentIndex, totalCount, CONTAINER_GAP, totalPaginationDots, props.perPage, props.dynamicPerPage, CONTAINER_PADDING, slideWidth]
  );

  const updateSlideWidth = useCallback(() => {
    const containerWidth = sliderContainerRef.current?.clientWidth ?? 0;
    if (dynamicPerPage) {
      const maxItems = Math.floor((containerWidth - CONTAINER_PADDING * 2 + CONTAINER_GAP) / (CARD_WIDTH + CONTAINER_GAP));
      setSlideWidth(CARD_WIDTH);
      setPerPage(maxItems);
    } else if (perPage === 1) {
      setSlideWidth(containerWidth);
    } else {
      setSlideWidth(CARD_WIDTH);
    }
  }, [CONTAINER_GAP, CONTAINER_PADDING, dynamicPerPage, perPage]);

  useEffect(() => {
    updateSlideWidth();
    window.addEventListener('resize', updateSlideWidth);

    return () => {
      window.removeEventListener('resize', updateSlideWidth);
    };
  }, [updateSlideWidth]);

  useEffect(() => {
    if (isSliderEnabledPrev && !isSliderEnabled) {
      setOffset(() => 0);
      setCurrentIndex(() => 0);
    }
  }, [currentIndex, isSliderEnabled, isSliderEnabledPrev]);

  useEffect(() => {
    if (!autoPlay || !isSliderEnabled) return;

    const interval = setInterval(() => {
      if (currentIndex < totalPaginationDots - 1) {
        handleChangeSlide(currentIndex + 1);
      } else if (config?.loop) {
        handleChangeSlide(0);
      }
    }, autoPlayInterval);

    return () => {
      clearInterval(interval);
    };
  }, [autoPlay, autoPlayInterval, currentIndex, isSliderEnabled, totalPaginationDots, config?.loop, handleChangeSlide]);

  const onTouchStart = useCallback((e: React.TouchEvent<HTMLDivElement>) => {
    setTouchStart(e.targetTouches[0].clientX);
  }, []);

  const onTouchMove = useCallback((e: React.TouchEvent<HTMLDivElement>) => {
    setTouchEnd(e.targetTouches[0].clientX);
  }, []);

  const onTouchEnd = useCallback(() => {
    if (touchStart !== null && touchEnd !== null) {
      if (touchStart - touchEnd > threshold && currentIndex < totalPaginationDots - 1) {
        handleChangeSlide(currentIndex + 1);
      } else if (touchStart - touchEnd > threshold && currentIndex === totalPaginationDots - 1 && config?.loop) {
        handleChangeSlide(0);
      }

      if (touchStart - touchEnd < -threshold && currentIndex > 0) {
        handleChangeSlide(currentIndex - 1);
      } else if (touchStart - touchEnd < -threshold && currentIndex === 0 && config?.loop) {
        handleChangeSlide(totalPaginationDots - 1);
      }
    }

    setTouchStart(null);
    setTouchEnd(null);
  }, [touchStart, touchEnd, currentIndex, totalPaginationDots, config?.loop, handleChangeSlide]);

  const onMouseDown = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    setMouseDown(true);
    setTouchStart(e.clientX);
    e.preventDefault();
  }, []);

  const onMouseMove = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (mouseDown) {
        setTouchEnd(e.clientX);
        e.preventDefault();
      }
    },
    [mouseDown]
  );

  const onMouseUp = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    setMouseDown(false);

    if (touchStart !== null && touchEnd !== null) {
      if (touchStart - touchEnd > threshold && currentIndex < totalPaginationDots - 1) {
        handleChangeSlide(currentIndex + 1);
      } else if (touchStart - touchEnd > threshold && currentIndex === totalPaginationDots - 1 && config?.loop) {
        handleChangeSlide(0);
      }

      if (touchStart - touchEnd < -threshold && currentIndex > 0) {
        handleChangeSlide(currentIndex - 1);
      } else if (touchStart - touchEnd < -threshold && currentIndex === 0 && config?.loop) {
        handleChangeSlide(totalPaginationDots - 1);
      }
    }

    setTouchStart(null);
    setTouchEnd(null);
    e.preventDefault();
  }, [touchStart, touchEnd, currentIndex, totalPaginationDots, config?.loop, handleChangeSlide]);

  const handlers = {
    onTouchStart,
    onTouchMove,
    onTouchEnd,
    onMouseDown,
    onMouseMove,
    onMouseUp
  };

  return (
    <div
      className={`max-w-[1280px] px-8 md:px-4 w-full mx-auto relative ${config?.slider?.wrapperClassNames ?? ''}`}
      ref={sliderContainerRef}
      style={{
        padding: `0 ${(perPage === 1 && !dynamicPerPage) ? 0 : CONTAINER_PADDING}px`
      }}
    >
      <div
        className={`relative top-0 left-0 flex transition-transform duration-500 ${!isSliderEnabled ? 'justify-center' : ''} ${config?.slide?.wrapperClassNames ?? ''}`}
        style={{
          transform: `translateX(-${sliderOffset}px)`,
          gap: `${(perPage === 1 && !dynamicPerPage) ? 0 : CONTAINER_GAP}px`
        }}
        ref={slidesContainerRef}
        {...handlers}
        onDragStart={(e) => e.preventDefault()}
      >
        {children && !!slideWidth && React.Children.map(children, (child, index) => (
          <div
            key={index}
            className={`flex-shrink-0`}
            style={{ width: `${slideWidth}px` }}
          >
            {React.cloneElement(child as React.ReactElement, { ...(child as React.ReactElement).props })}
          </div>
        ))}
      </div>
      {!!slideWidth && isSliderEnabled && (
        <div
          className={`absolute -bottom-12 left-1/2 transform -translate-x-1/2 flex gap-2xl z-20 ${config?.pagination?.dotsContainerStyle ? config.pagination.dotsContainerStyle : '-bottom-12'}`}>
          {Array.from({ length: totalPaginationDots }, (_, index) => (
            <div
              key={index}
              className={`w-[10px] h-[10px] cursor-pointer rounded-full ${index === currentIndex ? config?.pagination?.activeDotStyle || 'bg-success-500' : config?.pagination?.inactiveDotStyle || 'bg-gray-200'}`}
              onClick={() => handleChangeSlide(index)}
            />
          ))}
        </div>
      )}
    </div>
  );
};
