'use client';

import { FC, useMemo, useState } from 'react';

import { Button } from 'components/ui/button';
import { Dialog, DialogContent } from 'components/ui/dialog';
import { PlayButton } from 'components/icons/v2';
import { extractVideoId } from 'utils/video';

interface IVideoButton {
  link: string;
}

export const VideoButton: FC<IVideoButton> = (props) => {
  const { link } = props;

  const videoIframeUrl = useMemo(() => `https://www.youtube.com/embed/${extractVideoId(link)}`, [link]);

  const [isOpen, setOpen] = useState<boolean>(false);

  return (
    <>
      <Button variant="nakedGray" size="x-large" className="sm:hidden" onClick={() => setOpen(prevState => !prevState)}>
        <PlayButton width={20} height={20} />
        Welcome Video
      </Button>
      <Dialog open={isOpen} onOpenChange={open => setOpen(() => open)}>
        <DialogContent className="pt-10 min-w-[626px] md:min-w-0">
          <iframe width="100%" height="315" src={videoIframeUrl}
                  title="YouTube video player" frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen></iframe>
      </DialogContent>
    </Dialog>
</>
)
  ;
};
